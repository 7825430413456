import React from 'react';
import { PlatformToolbar } from '@apex/react-toolkit/components';
import { Col, Container, Row } from 'react-bootstrap';

const App = () => {
  const { location } = window;
  const host = location.hostname.split('.');
  // const appName = !['dev', 'staging'].includes(host[0]) ? host[0] : host[1];
  const appName = host[0].includes('-') ? host[0].split('-')[1] : host[0];

  return (
    <Container fluid>
      <PlatformToolbar
        navigationItems={[]}
        name={appName}
      />
      <Row>
        <Col>
          <h1><span style={{ textTransform: 'capitalize' }}>{appName}</span> coming soon!</h1>
        </Col>
      </Row>
    </Container>
  );
};

export default App;
